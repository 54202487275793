// Trips is a container component that will render a list of TripCard components
import { useEffect, useMemo, useState } from "react";
import { isAfter, isBefore } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faX } from "@fortawesome/free-solid-svg-icons";

import { TripCard } from "./TripCard";
import { CustomRangeTripCard } from "./CustomRangeTripCard";
import { adjustTimeForUTCOffset, getFriendlyTimezoneName } from "../../utils";

import "./Trips.scss";

export const Trips = ({
  trips,
  onTripSelect,
  closeTripsModal,
  closestReportedTrace,
  searchClosestReportedTrace,
  querySelectedTrip = null,
  refreshTrips = undefined,
}: {
  trips: any[];
  onTripSelect: (trip: any) => void;
  closeTripsModal: () => void;
  closestReportedTrace: string;
  searchClosestReportedTrace: (date: string) => void;
  querySelectedTrip?: string | null;
  refreshTrips?: ({ forceReload }: { forceReload: boolean }) => void;
}) => {
  // Check if the latest trip is currently active
  const currentTrip = trips?.find((trip) => trip.isActive);

  // State to keep track of the selected trip
  const [selectedTrip, setSelectedTrip] = useState<string | null>(null);

  // On initial load, select all trips
  useEffect(() => {
    onTripSelect({ traces: trips.flatMap((trip) => trip.traces), id: "all" });
    setSelectedTrip("all");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If a trip is selected from the URL query, set the selected trip
  useEffect(() => {
    if (querySelectedTrip) {
      setSelectedTrip(querySelectedTrip);
    }
  }, [querySelectedTrip]);

  // State for startTime to endTime used in the custom range trip card
  const [customRange, setCustomRange] = useState<any>({
    startTime: "00:00",
    endTime: "23:59",
  });

  const handleTripSelection = (trip: any) => {
    onTripSelect(trip);
    setSelectedTrip(trip.id);
  };

  // Convert the custom range time to UTC time and check if the trace is within the custom range
  const isTimeInRange = (traceTime: string, timeZone: string) => {
    // Set the traceDate
    const [hours, minutes] = traceTime.split(":").map(Number);
    const traceDate = new Date();
    traceDate.setHours(hours, minutes, 0, 0);

    // Using util function to adjust time for UTC offset
    const startDate = adjustTimeForUTCOffset(customRange.startTime, timeZone);
    const endDate = adjustTimeForUTCOffset(customRange.endTime, timeZone);

    // Return true if the trace is within the custom range using date-fns
    return !isBefore(traceDate, startDate) && !isAfter(traceDate, endDate);
  };

  // Filter trips to only show if in the custom time range
  const customRangeTripTraces = useMemo(() => {
    const allTrips = [...trips.flatMap((trip) => trip.traces)];

    return allTrips.filter((trace) => {
      // Get the time of the trace in hh:mm format
      const traceHours = new Date(trace.timeStampUTC).getUTCHours();
      const traceMinutes = new Date(trace.timeStampUTC).getMinutes();
      const traceTime = `${traceHours}:${traceMinutes}`;

      // Return the trace if it is within the custom range time
      return isTimeInRange(traceTime, trace.timeZone);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customRange, trips]);

  return (
    <div className="trips-container">
      <div className="trips-header">
        <h1>Trip Summary</h1>
        <div className="position-relative">
          <div className="trips-controls">
            {/* If the date is today, show a refresh button */}
            {refreshTrips ? (
              <>
                <div className="position-relative">
                  <div
                    className="refresh-container "
                    onClick={() => refreshTrips({ forceReload: true })}
                  >
                    <span data-testid="refreshIcon">
                      <FontAwesomeIcon icon={faRefresh} />
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <span />
            )}

            <div
              className="x-container"
              onClick={closeTripsModal}
              data-testid="trips-close-btn"
            >
              <span aria-hidden="true">
                <FontAwesomeIcon icon={faX} />
              </span>
            </div>
          </div>
        </div>
      </div>

      {closestReportedTrace && (
        <div className="empty-trips-container">
          {/* Display either the closest reported trace or if none have been found */}
          {closestReportedTrace !== "No traces found" ? (
            <>
              <p>No trips have been found for the selected filters.</p>
              <p>
                The closest found trip for this vehicle is from {""}
                <span
                  title="Click to search for closest reported trip"
                  onClick={() =>
                    searchClosestReportedTrace(closestReportedTrace)
                  }
                  className="closest-reported-date"
                >
                  {closestReportedTrace}
                </span>
                .{" "}
              </p>
            </>
          ) : (
            <>
              <p>No trips have been found for this vehicle.</p>
              <p>Please confirm the VIN has been activated with ConnectOne.</p>
            </>
          )}
        </div>
      )}

      {!!trips?.length && (
        <div className="trip-cards-container">
          <CustomRangeTripCard
            key={"custom-range-trips"}
            trip={{
              traces: customRangeTripTraces,
              id: "custom-range",
            }}
            customRange={customRange}
            setCustomRange={setCustomRange}
            onTripSelect={handleTripSelection}
            selectedTrip={selectedTrip}
          />

          <TripCard
            key={"all-trips"}
            trip={{ traces: trips.flatMap((trip) => trip.traces), id: "all" }}
            customTitle="All Trips"
            onTripSelect={handleTripSelection}
            selectedTrip={selectedTrip}
          />

          {!!currentTrip && (
            <TripCard
              key={"all-trips"}
              trip={{
                ...currentTrip,
                id: "current",
              }}
              dateRange={`${
                currentTrip?.startTimeLocal
              } (${getFriendlyTimezoneName(
                currentTrip.startTimeZone,
                currentTrip.startTripDate,
                "short"
              )})`}
              onTripSelect={handleTripSelection}
              selectedTrip={selectedTrip}
            />
          )}

          {trips?.map((trip: any, index: number) => {
            if (trip.isActive) {
              // eslint-disable-next-line array-callback-return
              return;
            }
            return (
              <TripCard
                key={trip.id}
                trip={trip}
                onTripSelect={handleTripSelection}
                selectedTrip={selectedTrip}
                index={index}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
