// Custom hook to handle functionality and state used within the map page
import { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";

import { getCurrentVehicleTripAPI, getVehicleTripsAPI } from "../axios";
import {
  filterTripData,
  getPointMeasurements,
  shapeTraceData,
  shapeTripData,
} from "../utils";
import { useIsMobile } from "./useIsMobile";
import { isEmpty } from "lodash";

export const useMap = ({
  map,
  locationData,
  queryParamsLocation,
}: {
  map: any;
  locationData: any;
  queryParamsLocation?: any;
}) => {
  const isMobile = useIsMobile();

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [measurementData, setMeasurementData] = useState<any>({});
  const [clearRulerLine, setClearRulerLine] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [showFilters, setShowFilters] = useState(!isMobile);
  const [showLookupModal, setShowLookupModal] = useState(false);
  const [showRulerModal, setShowRulerModal] = useState(false);
  const [dynamicFillColorsByType, setDynamicFillColorsByType] = useState([]);
  const [dynamicFillColorsByStatus, setDynamicFillColorsByStatus] = useState(
    []
  );

  // Map Zoom - If user has queried for a specific location, zoom in is 17 else default is 4
  const [mapZoom, setMapZoom] = useState(queryParamsLocation ? 17 : 4);
  const maxZoom = 10; // Max zoom before clusters are hidden

  // Handle if user has queried for a specific trip via the vehicle traces report
  const [queriedTrip, setQueriedTrip] = useState<string | null>(null);
  const [queriedTrace, setQueriedTrace] = useState<string | null>(null);

  // Trips/Traces state
  const [tripPoints, setTripPoints] = useState<any>({
    startPoint: null,
    endPoint: null,
  });
  const [displayTripsModal, setDisplayTripsModal] = useState(false);
  const [vehicleTraces, setVehicleTraces] = useState([]);
  const [vehicleTrips, setVehicleTrips] = useState<any>(null);
  const [closestReportedTrace, setClosestReportedTrace] = useState<any>(null);
  const [viewingCurrentTrip, setViewingCurrentTrip] = useState(false);
  const [showTraceBalloons, setShowTraceBalloons] = useState({
    startBalloon: true,
    endBalloon: true,
  });

  const [vinByLookup, setVinByLookup] = useState(false);

  // Track if filters have been changed
  const [filterChanged, setFilterChanged] = useState(false);

  const locationStatuses = useSelector(
    (state: any) => state.locationStatuses.locationStatusesData
  );

  // Map Styling
  const [mapStyle, setMapStyle] = useState({
    style: "GNSS_Tolling_UI-Hybrid",
    name: "Hybrid",
  });

  // Filters
  const [filterValues, setFilterValues] = useState({
    vin: {
      type: "search",
      label: "Search trips by VIN...",
      value: "",
      labelHidden: !isMobile,
    },
    date: {
      type: "date",
      value: `${format(new Date(), "yyyy-MM-dd")}`,
      label: `${isMobile ? "Date / Time Start" : ""}`,
    },
  });

  // If trips are loaded in background we want to display a "View" button
  const tripsLoaded = !filterChanged && vehicleTrips;

  // Handle Map Style
  const handleMapStyleSwitch = (style: string) => {
    if (style === "Hybrid") {
      setMapStyle({ style: "GNSS_Tolling_UI-Hybrid", name: "Hybrid" });
    } else if (style === "Basic") {
      setMapStyle({ style: "GNSS_Tolling_UI-Explore", name: "Basic" });
    } else if (style === "Satellite") {
      setMapStyle({ style: "GNSS_Tolling_UI-Imagery", name: "Satellite" });
    }
  };

  // Set dynamic fill colors for location types
  useEffect(() => {
    const fillColors: string[] = [];

    if (isEmpty(locationData)) return;

    // Loop over each key of locationData
    for (let [key, value] of locationData) {
      const { geofenceFillColor } = value;
      fillColors.push(key, geofenceFillColor);
    }

    setDynamicFillColorsByType(fillColors);
  }, [locationData]);

  // Set dynamic fill colors for location statuses
  useEffect(() => {
    const fillColors: string[] = [];

    if (isEmpty(locationStatuses)) return;

    // Loop over each key of locationData
    for (let locationStatus of locationStatuses) {
      if (locationStatus.name === "Active") continue;
      fillColors.push(locationStatus._id, "#222222");
    }

    setDynamicFillColorsByStatus(fillColors);
  }, [locationStatuses]);

  // Handle Cluster Click
  const handleClusterClick = (e: any) => {
    const features = map.current?.queryRenderedFeatures(e.point, {
      layers: ["clusters"],
    });

    if (features && features.length > 0) {
      // Find the cluster id
      const clusterId = features[0].properties?.cluster_id;

      // If no clusterId, assume user clicked a single point which does not act same as a cluster and ease to that point
      if (!clusterId) {
        const coordinates = features[0].geometry.coordinates;
        map.current?.easeTo({
          center: coordinates,
          zoom: mapZoom + 2,
        });
        return;
      }

      const cluster = map.current?.querySourceFeatures("geofenceClusters", {
        sourceLayer: "geofenceClusters",
        filter: ["==", "cluster_id", clusterId],
      });

      if (cluster && cluster.length > 0) {
        // Get the coordinates of the cluster
        const coordinates = cluster[0].geometry.coordinates;

        // Ease into the cluster
        map.current?.easeTo({
          center: coordinates,
          zoom: mapZoom + 2,
        });

        setMapZoom(mapZoom + 2);
      }
    }
  };

  // Handle Filters
  const handleFilterChanges = (e: any) => {
    const { name, value } = e.target || e;

    setFilterValues({
      ...filterValues,
      // @ts-ignore
      [name]: { ...filterValues[name], value: value },
    });
    setFilterChanged(true);
    setQueriedTrip(null);
    setQueriedTrace(null);

    return;
  };

  // Display refresh button if the trip date is today
  const displayRefreshButton = () => {
    if (!vehicleTrips?.length) return true;
    if (filterChanged) return false;

    // Get start of first trip and end of last trip
    const firstTrip = vehicleTrips[0];
    const lastTrip = vehicleTrips[vehicleTrips.length - 1];

    // Get date string of each
    const firstTripDate = firstTrip.startTripDate.split("T")[0];
    const lastTripDate = lastTrip.endTripDate.split("T")[0];
    const todaysDate = format(new Date(), "yyyy-MM-dd");

    // Check if either day is today
    return firstTripDate === todaysDate || lastTripDate === todaysDate;
  };

  // Poll for data every <delay> seconds
  const useInterval = (callback: any, delay: number) => {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        if (viewingCurrentTrip) {
          // @ts-ignore
          savedCallback.current();
        }
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay, viewingCurrentTrip]);
  };

  // Handle Trip Search
  const handleTripSearch = async ({
    forceReload = false,
    selectedVin = null,
    selectedDate = null,
  }: {
    forceReload?: boolean;
    selectedVin?: string | null;
    selectedDate?: string | null;
  }) => {
    try {
      // Is user closed the modal but has not changed the trip data, reopen the modal
      // If forceReload is true, we want to reload the data
      if (!forceReload && vehicleTrips && !filterChanged) {
        setDisplayTripsModal(true);
        return;
      }
      // If the user has changed the filters for search, reset the state
      setFilterChanged(false);
      setViewingCurrentTrip(false);
      setDisplayTripsModal(false);
      setVehicleTraces([]);
      setVehicleTrips([]);
      setIsDataLoading(true);
      await getVehicleTripData({ selectedVin, selectedDate });
      setDisplayTripsModal(true);
    } catch (err) {
      console.log("Get Vehicle Trace Data error: ", err);
    }
    setIsDataLoading(false);
  };

  // Zoom to the starting point of the trip
  const flyToTracePoint = (tracePointData: any) => {
    if (!map.current || !tracePointData) return;

    map.current.flyTo(
      {
        center: [tracePointData?.longitude, tracePointData?.latitude],
        zoom: 12,
      },
      []
    );
  };

  // Get Vehicle Trip Data
  const getVehicleTripData = async ({
    selectedVin = null,
    selectedDate = null,
  }: {
    selectedVin: string | null;
    selectedDate: string | null;
  }) => {
    const response = await getVehicleTripsAPI({
      vin: selectedVin || filterValues.vin.value,
      date: new Date(
        selectedDate ? selectedDate : filterValues.date.value
      ).valueOf(),
    });

    // If there are no traces found, set the closest reported trace
    if (response.closestReportedTrace) {
      setClosestReportedTrace(response.closestReportedTrace);
      return;
    }

    const tripsData = shapeTripData(response);
    setVehicleTrips(tripsData);
    setClosestReportedTrace(null);
    return tripsData;
  };

  // Get Vehicle Traces Data
  const getVehicleTraceData = async () => {
    const endTimestamp = tripPoints.endPoint.timestamp;
    const response = await getCurrentVehicleTripAPI({
      vin: filterValues.vin.value,
      dateFrom: endTimestamp,
    });

    const data = shapeTraceData(response);
    const filteredTraces = filterTripData(data);

    // Want to just push the new traces to the existing traces
    setVehicleTraces((prevTraces) => [...prevTraces, ...filteredTraces]);
  };

  // Handle Trip Selection
  const selectVehicleTrip = async (trip: any, trace: any = null) => {
    setShowTraceBalloons({
      startBalloon: trip.id !== "all" && trip.id !== "custom-range",
      endBalloon:
        trip.id !== "all" &&
        trip.id !== "current" &&
        trip.id !== "custom-range",
    });

    // If the user has selected the current trip we need to start polling for new trace data
    setViewingCurrentTrip(trip.id === "current");

    // Assuming that if no trace is sent, we want to clear the queried trip and trace
    if (!trace) {
      setQueriedTrip(null);
      setQueriedTrace(null);
    }

    // If a specific trace is sent, make sure it is not filtered out
    const filteredTraces = filterTripData(trip.traces, trace?._id);
    setVehicleTraces(filteredTraces);
    flyToTracePoint(trace || filteredTraces[filteredTraces.length - 1]);
    setTripPoints({
      startPoint: filteredTraces[filteredTraces.length - 1],
      endPoint: filteredTraces[0],
    });
  };

  // Handle Closest Reported Trace Search
  const searchClosestReportedTrace = async (date: string) => {
    // Format the date to "yyyy-MM-dd"
    date = format(new Date(date), "yyyy-MM-dd");

    // Update the date filter to the closest reported trace date
    setFilterValues({
      ...filterValues,
      date: {
        ...filterValues.date,
        value: date,
      },
    });

    // Search for the closest reported trace
    await handleTripSearch({ forceReload: true, selectedDate: date });
  };

  // Close Trips Modal
  const closeTripsModal = () => {
    setDisplayTripsModal(false);
  };

  // Handle Mobile Menu
  const handleOpenMobileMenu = (toggledType: string) => {
    if (toggledType === "filterBar") {
      setShowControls(false);
      setShowFilters(!showFilters);
    } else if (toggledType === "controls") {
      if (isMobile) setShowFilters(false);
      setShowControls(!showControls);
    }
  };

  // Handle Map Ruler
  const handleMapMeasure = ({
    startPoint,
    endPoint,
  }: {
    startPoint: number[];
    endPoint: number[];
  }) => {
    setClearRulerLine(false);
    setShowRulerModal(true);
    setMeasurementData(getPointMeasurements({ startPoint, endPoint }));
  };

  const handleMapMeasureEnd = () => {
    setClearRulerLine(true);
    setShowRulerModal(false);
    setMeasurementData({});
  };

  return {
    mapStyle,
    tripsLoaded,
    isDataLoading,
    filterValues,
    showControls,
    showFilters,
    showLookupModal,
    showRulerModal,
    dynamicFillColorsByType,
    dynamicFillColorsByStatus,
    measurementData,
    clearRulerLine,
    vehicleTraces,
    vehicleTrips,
    closestReportedTrace,
    displayTripsModal,
    showTraceBalloons,
    tripPoints,
    queriedTrace,
    queriedTrip,
    mapZoom,
    maxZoom,
    vinByLookup,
    setVinByLookup,
    displayRefreshButton,
    setShowLookupModal,
    useInterval,
    setMapStyle,
    handleTripSearch,
    handleFilterChanges,
    handleMapStyleSwitch,
    handleOpenMobileMenu,
    handleMapMeasure,
    handleMapMeasureEnd,
    flyToTracePoint,
    closeTripsModal,
    selectVehicleTrip,
    getVehicleTripData,
    getVehicleTraceData,
    searchClosestReportedTrace,
    setFilterValues,
    setQueriedTrip,
    setQueriedTrace,
    handleClusterClick,
    setMapZoom,
    setFilterChanged,
  };
};
